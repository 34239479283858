@import "../../variables.scss";
.references-popup {
  &-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* overlay를 최상위로 */
  }

  &-content {
    position: fixed;
    top: 0;
    right: 0;
    width: 60vw;
    height: 100vh;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    color: $text-color;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h3 {
        font-size: 1.25rem;
        font-weight: bold;
      }

      .close-button {
        font-size: 2rem;
        color: $text-color;
        cursor: pointer;
        border: none;
        background: none;

        &:hover {
          color: $primary-color;
        }
      }
    }

    .references-container {
      flex: 1;
      overflow-y: auto;
      padding: 0 1rem;
      margin-top: 0.5rem;
      padding-bottom: 4rem;
    }

    .references-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .title {
        font-size: 1rem;
      }

      .content {
        line-height: 1.6; /* 읽기 편한 간격 */
        word-wrap: break-word; /* 긴 단어 줄바꿈 처리 */
      }
    }
  }
}
