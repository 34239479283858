@import "../../variables.scss";

.comparison-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 64rem;
  //   margin: 0 auto;
  margin-left: 0.5rem;
}

.comparison-box {
  flex: 1;
  border: 1px solid $guide-border;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.comparison-header {
  background-color: $guide-bg;
  padding: 0.1rem 1rem;
  border-bottom: 1px solid $guide-border;
}

.comparison-title {
  font-size: 0.85rem;
  font-weight: 500;
  color: $sub-text-color;
}

.comparison-content {
  padding: 1rem;
  background-color: white;
}

.arrow-container {
  flex-shrink: 0;
  color: $sub-text-color;
}

.arrow-icon {
  width: 20px;
  height: 20px;
}
