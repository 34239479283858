@import "../../variables.scss";

.chat-guide {
  background-color: $guide-bg;
  border: 1px solid $guide-border;
  border-radius: 8px;
  padding: 2.5px 2.5px;
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
  margin-top: 5px;

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 5px;
      color: $sub-text-color;
    }
  }

  strong {
    color: $primary-color;
  }
}
