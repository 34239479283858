@import "../../variables.scss";

/* chat-container > messages-container */

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-in-out;
  &.sidebar-open {
    margin-left: calc($sidebar-width + 3rem);
  }

  margin-right: $container-margin-width;
  margin-left: calc($container-margin-width);

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 50px;
    margin-right: $container-margin-width;
    border-bottom: 1px solid $guide-border;

    #menu {
      display: flex;
      gap: 10px;
      height: 40px;
      margin-bottom: 0;
      margin-left: 30px;

      button {
        padding: 8px 16px;
        cursor: pointer;
        background-color: $guide-bg;
        font-weight: 600;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid $guide-border;
        border-bottom: 1px solid transparent;
      }

      #active {
        background-color: $primary-color;
        color: white;
        font-weight: 800;
        border: 1px solid $primary-color;
      }

      #deactive:hover {
        background-color: darken($user-bg, 5%);
      }
    }
    img {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 1050px) {
      #menu {
        margin-left: 50px;
      }
    }
  }
}

//messages
.messages-container {
  flex: 1;
  overflow-y: auto;
  background-color: white;
  font-weight: 500;
  margin-top: 1rem; // chatGuide가 없는 경우 추가

  .message-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.assistant {
      align-items: flex-start;
    }

    &.user {
      align-items: flex-end;
    }
  }

  .message {
    display: flex;
    align-items: flex-start;
    min-width: 100%;

    &.assistant {
      flex-direction: row;
    }

    &.user {
      flex-direction: row-reverse;
    }

    .message-content {
      padding: 0rem 0.5rem;
      border-radius: 18px;
      max-width: 70%;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      font-size: $desktop-fs;
      p {
        padding: 0;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
      line-height: 140%;

      .pdfButton {
        border: transparent;
        padding: 0.2rem 0.5rem;
        margin-right: 0.25rem;
        margin-bottom: 0.5rem;
        color: $sub-text-color;
        background-color: transparent;
        font-size: 0.8rem;
      }

      .pdfButton:hover {
        cursor: pointer;
        color: $primary-color;
      }
    }

    @media (max-width: 650px) {
      .message-content {
        font-size: $mobile-fs;
      }
    }

    pre,
    code {
      white-space: pre-wrap; // 코드 블록 줄바꿈
      word-break: break-all; // 모든 문자에서 줄바꿈 허용
    }

    &.assistant {
      .message-content {
        border: 1px solid $assistant-border;
        color: $text-color;
        border-bottom-left-radius: 0;
        margin-left: 10px;
        max-width: 70vw;
      }

      a {
        color: $primary-color;
        word-break: break-all;
      }
    }

    &.user {
      .message-content {
        border: 1px solid $user-border;
        color: $text-color;
        border-bottom-right-radius: 0;
        // margin-right: 10px;
        max-width: 70vw;
      }
    }
  }

  //   .assistant-icon {
  //     width: 30px;
  //     height: 30px;
  //     border-radius: 50%;
  //     object-fit: cover;
  //     margin-top: 1rem;
  //   }
}

.assistant-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  // margin-top: 1rem;
}

.message-content.generating {
  background-color: transparent !important;
  border: 1px solid $assistant-bg;
  color: darken($text-color, 20%);
  display: flex;
  align-items: center;
  white-space: pre-wrap; // 줄바꿈 보존
  word-break: break-word; // 긴 단어 줄바꿈

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    margin-left: 8px;
    background-color: $primary-color;
    border-radius: 50%;
    display: inline-block;
    animation: pulse 1s infinite ease-in-out;
  }

  .generating-message-container {
    max-height: 300px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .generating-line {
    margin: 0;
    padding: 0.1rem 0;
    animation: fadeIn 0.2s ease-in-out;
  }
}

.text-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  height: 100%;
  overflow-y: auto;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

.message-content.error {
  border: transparent !important;
  background-color: $assistant-bg;
  color: darken($text-color, 20%);
  font-style: italic;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem !important;
  font-weight: 600;
}

// form (메세지 입력)
.input-form {
  display: flex;
  margin-bottom: $container-margin-height;

  textarea {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid darken($secondary-color, 10%);
    border-radius: 10px;
    // font-size: 0.8rem;
    font-size: 16px;
    outline: none;

    &:focus {
      border-color: $primary-color;
    }
  }

  button {
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    max-height: 4rem;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      background-color: lighten($primary-color, 20%);
      cursor: not-allowed;
    }
  }

  .stop {
    background-color: $text-color;
  }
}

// 메일답변 복사 버튼
#copy {
  display: flex;
  float: right;
  margin-bottom: 0.5rem;
  background-color: white;
  border: transparent;
}

#copy:hover {
  cursor: pointer;
  color: $primary-color;
}

// onBoadrding Guide
.onBoardingGuide {
  color: $text-color;
  text-align: left;
  width: fit-content;
  border: transparent;
  background-color: transparent;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  background-color: $guide-bg;
  border-radius: 4px;
  margin-bottom: 0.25rem;
}
.onBoardingGuide:hover {
  color: $primary-color;
  cursor: pointer;
  background-color: darken($guide-bg, 2%);
}

// FAQ button
/* animations.css */
.faq-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.faq-button {
  background-color: white;
  border: transparent;
  // opacity: 0;
  // transform: translateY(10px);
  // animation: fadeInUp 0.5s ease forwards;
}

.faq-button button {
  font-size: 0.95rem;
  font-weight: 500;
  color: $text-color !important;
  display: inline-block;
}

// .faq-buttons-container .faq-button {
//   animation-fill-mode: forwards; /* 애니메이션 후 상태를 유지 */
// }

.faq-button:hover {
  color: $primary-color;
  cursor: pointer;
}

// @keyframes fadeInUp {
//   from {
//     opacity: 0;
//     transform: translateY(10px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
