@import "../../variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: Arial, sans-serif;
  position: relative;
  overflow-x: hidden;
}

.sidebar {
  width: $sidebar-width;
  background-color: $secondary-color;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      color: $primary-color;
    }
  }

  form {
    margin-top: 20px;
    margin-right: 20px;

    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid darken($secondary-color, 10%);
      border-radius: 5px;
      resize: vertical;
      margin-right: 50px;
    }

    button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .sidebar-switchMode {
    margin-bottom: 1rem;
    button {
      width: 100%;
      border-radius: 10px;
      border: transparent;
      background-color: transparent;
      justify-content: flex-start;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease;
      padding-left: 0;
      font-size: 15px;

      #icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        // background-color: white;
        flex-shrink: 0;
        margin-right: 10px;
      }
    }

    button:hover {
      cursor: pointer;
      background-color: darken($secondary-color, 5%);
    }

    .active {
      color: $primary-color;
      font-weight: 600;
    }
    .deactive {
      color: $text-color;
      img {
        filter: grayscale(100%);
        transition: filter 0.2s ease;
      }
    }
  }
}

.close-sidebar,
.open-sidebar {
  background-color: $primary-color;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.close-sidebar {
  font-size: 18px;
  padding: 5px 8px;
}

.open-sidebar {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999;
  font-size: 1.2rem;
  padding: 8px 10px;
}

hr {
  background-color: lighten($sub-text-color, 30%);
  height: 1px;
  border: 0;
}

// chat history list
.sorted-list-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 50vh;
}

.sorted-list {
  height: 100%;
  overflow-y: auto;
  background-color: $secondary-color;
  border-radius: 8px;

  // 스크롤바 스타일링
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($sub-text-color, 30%);
    border-radius: 4px;

    &:hover {
      background: $sub-text-color;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;
      margin-bottom: 5px;
      background-color: transparent;
      border-radius: 8px;
      font-size: 0.85rem;

      &:hover {
        background-color: lighten($secondary-color, 20%);
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    li.selected {
      background-color: white;
    }
  }
}
