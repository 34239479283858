// color
$primary-color: #e10c3e;
$secondary-color: #f0f2f6;
$mail-color: #0c61e1;
$text-color: #333;
$sub-text-color: #6c757d;
$assistant-border: lighten(#e10c3e, 40%);
$assistant-bg: rgba(251, 85, 124, 0.109);
$user-border: #dadada;
$user-bg: #f5f5f5;
$guide-bg: #f8f9fa;
$guide-border: #e9ecef;

// font size
$desktop-fs: 0.95rem;
$mobile-fs: 0.8rem;

// width
$sidebar-width: 20rem;

//margin & padding
$container-margin-width: 2vw;
$container-margin-height: 2vh;
