@import "./variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}

:root {
  --safe-area: max(env(safe-area-inset-bottom), 20px);
}

// 인증관련 화면
.auth-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.auth-form {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.auth-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  padding-right: 10px;
}

// app
.app-container {
  display: flex;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  font-family: Arial, sans-serif;
  position: relative;
  overflow-x: hidden;
  // &.file-viewer-open {
  //   padding-right: 50%; // FileViewer가 열릴 때 채팅 영역을 왼쪽으로 이동
  // }
}
