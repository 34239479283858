@import "../../variables.scss";

.file-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // 반투명한 검은색 오버레이
  z-index: 999; // FileViewer보다 낮은 z-index
}

.file-viewer {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $guide-bg;

    #file-viewer-title {
      font-weight: 600;
      i {
        font-size: 0.85rem;
        font-style: normal;
        color: $sub-text-color;
      }
    }

    button {
      border: transparent;
      border-radius: 5px;
      padding: 0.5rem;
      margin-right: 1rem;
      background-color: $text-color;
      color: white;
    }
    button:hover {
      background-color: $primary-color;
    }

    #zoom {
      background-color: $sub-text-color;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
    }
    #zoom:hover {
      background-color: $text-color;
    }
  }

  &-content {
    flex: 1;
    overflow: hidden;
  }
}

.error {
  /* 컨테이너 위치 조정 */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90vw, 500px);
  padding: clamp(1rem, 3vw, 2.5rem);
  text-align: center;
  border: 1.5px solid $user-border;
  border-radius: 10px;
  background-color: white;
  line-height: 1.5;
  font-weight: 600;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-break: break-word;
}

.file-viewer-content {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

/* 화면 크기에 따른 조절 */
@media only screen and (max-width: 500px) {
  .file-viewer {
    width: 100vw;
  }
  .error {
    font-size: 0.85rem;
  }
}
