@import "../../variables.scss";
.onboarding-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 50;
}

.onboarding-card {
  width: 100%;
  max-width: 40vw;
  position: relative;
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
  color: black;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.close-icon {
  position: relative;
  width: 1rem;
  height: 1rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $sub-text-color;
    top: 50%;
    left: 0;
    transition: background-color 0.2s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.card-content {
  padding: 2.5rem 2rem 2rem;
}

.content-wrapper {
  //   text-align: center;
  margin-bottom: 2rem;
  min-height: 120px;
}

.title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: $text-color;
}

// .description {
//   //   color: $sub-text-color;
//   color: $text-color;
//   line-height: 1.6;
//   font-size: 1.1rem;
// }

// .image {
//   max-width: 100%;
// }

// .icon {
//   width: 5rem;
// }
.content-with-icon {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
  color: $text-color;
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 500;
  .text-content {
    flex: 1;
  }

  .content-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}

.guideImage {
  max-width: 100%;
}

i {
  font-style: normal;
  background-color: $user-bg;
  color: $sub-text-color;
  padding: 0.1rem;
  font-size: 0.85rem;
}

.feature-selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.feature-button {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  border: 2px solid $user-border;
  border-radius: 0.75rem;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;

  &:hover {
    border-color: $primary-color;
  }

  &.selected {
    border-color: $primary-color;
    background: lighten($primary-color, 50%);
  }
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: white;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.feature-text {
  flex: 1;

  h3 {
    font-weight: 600;
    margin: 0 0 0.25rem;
    color: $text-color;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    color: $sub-text-color;
    line-height: 1.4;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1.5rem;
}

.nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border: 1px solid $user-border;
  border-radius: 0.5rem;
  background: white;
  color: $text-color;
  font-weight: 500;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: darken($user-bg, 1%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.start {
    background: $primary-color;
    color: white;
    border: none;

    &:hover:not(:disabled) {
      background: darken($primary-color, 5%);
    }

    &:disabled {
      background: $sub-text-color;
    }
  }
}

.arrow-left,
.arrow-right {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-style: solid;
    border-width: 2px 2px 0 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.arrow-left::before {
  border-color: currentColor;
  left: 0.25rem;
  transform: translateY(-50%) rotate(-135deg);
}

.arrow-right::before {
  border-color: currentColor;
  right: 0.25rem;
  transform: translateY(-50%) rotate(45deg);
}

.indicators {
  display: flex;
  gap: 0.375rem;
}

.indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: $user-border;
  transition: all 0.3s ease;

  &.active {
    background-color: $primary-color;
    transform: scale(1.2);
  }
}

.dontShowAgain {
  margin-top: 1.5rem;
  button {
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    border: transparent;
    background-color: $text-color;
    color: white;
    font-weight: 500;
  }
  button:hover {
    cursor: pointer;
    background-color: $primary-color;
  }
}

@media (max-width: 800px) {
  .onboarding-card {
    margin: 1rem;
    max-width: none;
  }
}

@media (max-width: 640px) {
  .onboarding-card {
    margin: 1rem;
    max-width: none;
  }

  .card-content {
    padding: 2rem 1.5rem 1.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .nav-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
